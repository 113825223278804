.form-card{
    margin-left: 25%;
    height: 100%;
    width: 50%;
    align-items: center;
}

.report-table-card{
    margin-left: 2%;
    margin-top: -8.5%;
    height: 100%;
    width: 95%;
}