.App-footer {
    position: fixed;
    bottom:0;
    background-color: #282c34;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: calc(2vmin);
    color: white;
    text-align: center;
    margin-left: -15px;
    /* Footer is currently hidden */
    /* display: none; */
}

.App-footer > p {
    margin-bottom: 0;
}
