.auth-wrapper {
  height: 100%;
  width: 100%;
  margin: 0;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center;
  position: absolute;
  top: 0px;
}

.Authenticator {
  padding-top: 100px;
}

.login-header {
  margin-bottom: 5%;
}