.App-header {
  padding-top: 5px;
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
  margin: 0px -15px 0px -15px;  
}

.App-header > h1 {
  display: inline;
  font-size: 2rem;
  margin: 0px;
  margin-right: 50px;
}

.App-header > p {
  margin: 0px;
}

.sign-out {
    margin-left: 5%;
    text-decoration: none;
    cursor: pointer;
}

.username {
    font-size: 1.2rem;
}
.logo-style {
  height: 3.1rem;
}

.queues {
  margin-top : .5%;
  font-size: 1.5rem;
}