.metrics-area {
  padding: 2.5%;
  max-height: 98vh;
  border: none;
  overflow-y: show;
  overflow-x: hidden;
}

.metrics-area::-webkit-scrollbar {
  width: 0 !important;
  color: transparent !important;
}
