.form-div{
    display: flex;
    margin-top: 1.6%;
}

.label{
    font-size: 1.2em;
    margin-top: 0.8%;
}

.date-picker{
    width: 100%;
    margin-top: 2.4%;
    margin-left: 8%;
}

.submit{
    margin-top: 2.0%;
    margin-bottom: 1.0%;
    margin-left: 24%;
}

.modal-content{
    text-align: center;
    font-size: 1.2em;
}