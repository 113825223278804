.card {
  border-radius: 10px 10px 10px 10px !important;
  margin-bottom: 10%;
  border: none;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.171);
}

.card-header {
  padding: 4px;
  border-radius: 10px 10px 0px 0px !important;
  color: white;
  border: none;
}

.card-title {
  margin: 0;
}

.card-body {
  padding: 1%;
  border-radius: 0px 0px 10px 10px;
}

.icon {
  font-size: 1rem !important;
}

.card-table td {
  font-size: 1.2rem;
  font-weight: bold;
}

.card-table th {
  font-size: 0.83rem;
  font-weight: normal;
  color: #d9e2ff;
}

.card-table td {
  white-space: inherit !important;
}

.hide-btn {
  background-color: transparent;
  border: transparent;
}

.hide-btn:hover {
  background-color: transparent;
  border: transparent;
}

.hide-btn:focus {
  background-color: transparent;
  border: transparent;
}
