body {
  background-color: rgb(217, 229, 235);
  overflow-y: auto;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}


.App-link {
  color: #61dafb;
}

.main {
  min-height: 700px;
  height: 80%;
}

.amplify-tabs {
  display: none;
}

.login-header {
  text-align: center;
}



/* BADGES */ 
.bg-danger {
  background-color: rgb(216, 67, 67) !important;
}

.bg-success {
  background-color: rgb(54, 167, 73) !important;
}

.bg-primary {
  background-color: rgb(73, 115, 231) !important;
}

.bg-warning {
  background-color: rgb(231, 148, 23) !important;
}

.bg-purple {
  background-color: rgb(106, 52, 192) !important;
}

/* TEXT */

.text-purple {
  color: rgb(126, 64, 226) !important;
}

.text-primary {
  color: rgb(111, 145, 240) !important;
}

.text-blue {
  color: #3067C8 !important; 
}

.text-yellow {
  color : #daa804 !important;
}

.text-turquoise {
  color : #20aca8 !important;
}