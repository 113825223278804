.badge {
  padding: 5px;
}

td {
  background-color: red;
  white-space: nowrap !important;
  overflow: hidden;
}

td > i {
  font-size: 1.2rem;
}

.table-card {
  padding: 1%;
  max-height: 98vh;
  overflow-y: show;
}

.table-responsive::-webkit-scrollbar {
  width: 0px !important;
}

.agents-table tbody tr:hover {
  background-color: rgb(197, 232, 252);
}
