.main {
    padding-top: 0.5%;
}

.metrics-toggle{
    border:none;
    background-color: rgba(29, 89, 202, 0.623);
    border-radius:  5px 0px 0px 5px;
    z-index: 1;
    position: fixed;
    right: 0;
    top: 6%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.507);
}

.metrics-toggle:hover {
    background-color: rgba(27, 81, 180, 0.884);
    outline: none !important; 
    border: none !important;
    box-shadow: inherit !important;
}

.metrics-toggle:focus {
    background-color:rgba(41, 114, 250, 0.884) ;
}

.metrics-toggle > i {
    font-size: 2rem;
}

